@import "./variables.scss";

.home{
  overflow-x: hidden;
  width: 100%;
  .hero{
    width: 100%;
    min-height: 100vh;
    overflow-x: hidden;
    background-image: url(../images/bg-hero.jpg);
    background-repeat: no-repeat;
    background-position: center;
    @include mediaW (1000px) {
      background-size: 170%;
    }
    @include mediaW (1300px) {
      background-size: 130%;
      animation: zoom-bg 15s ease 0s infinite;
    }
    @include mediaM (999px) {
      background-size: 550%;
      animation: zoom-bg-mobile 15s ease 0s infinite;
    }
    @include mediaM (750px) {
      background-image: url(../images/bg-hero.jpg);
    }
    .sub-header{
      width: 100%;
      height: 9vmax;
    }
    .inner-hero{
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 5vmax 3vmin;
      width: 100%;
      min-height: calc(100vh - 9vmax);
      .hero-spread{
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 3vmin;
        width: 90%;
        max-width: 1200px;
        width: 100%;
        h1{
          text-shadow: 5px 3px 10px $black;
        }
        .info-box{
          padding: 12px;
          box-shadow: 0 0 10px 1px $white-faint1;
          background-color: $black-faint1;
        }
        .for-mobile{
          display: none;
        }
        @include mediaM(900px) {
          flex-direction: column;
          justify-content: center;
          text-align: center;
          .for-web{
            display: none;
          }
          .for-mobile{
            display: block;
          }
        }
      }
    }
  }
  .explore {
  overflow-x: hidden;
  width: 100%;
  .sub-header{
    width: 100%;
    height: 9vmax;
  }
  
  .hero {
    position: relative;
    overflow: hidden;
     background-image: url(../images/bg-about.jpg);
    background-size: cover;
    background-position: center;
    .buttom-pic {
      position: absolute;
      left: 20%;
      width: 60%;
      bottom: 0;
      img {
        width: 100%;
      }
    }
  }
  .select-switch {
    width: 70px;
    height: 40px;
    padding: 5px;
    border-radius: 20px;
    div {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: red;
    }
    .item-side {
      margin-left: 30px;
    }
  }
}
  .license-sect {
    position: relative;
    overflow: hidden;
    background-color: #000;
    .bg, .bg-top, .bg-bottom{
      position: absolute;
      width: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0.07;
      img{
        width: 100%;
      }
      @include mediaM(1500px){
        width: 1900px
      }
    }
    .bg-top, .bg-bottom{
      top: 10%;
      transform: translate(-50, 0);
      display: none;
      @include mediaM(750px){
        width: 1200px;
        display: block;
      }
    }
    .bg-bottom{
      top: 90%;
    }
  }
  .how-to-pattern{
    background-image: url(../images/bg-how-to.jpg);
    .for-web{
      margin-right: 20px;
      padding-right: 20px;
      @include mediaM(750px){
        display: none;
      }
    }
    .steps {
      display: grid;
      grid-template-columns: 115px auto;
      @include mediaM(750px){
        grid-template-columns: 75px auto;
      }
    }
  }
  .partners{
    background-image: url(../images/bg-partner-scifi.jpg);
    background-size: cover;
    background-position: center;
    min-height: 70vh;
    .partner-card{
      position: relative;
      padding: 10%;
      box-shadow: 0 0 10px 1px $white-faint1;
      background-color: $black-faint1;
    }
  }
}
.upload {
  overflow-x: hidden;
  width: 100%;
  .upload-space {
    border: 1px dashed $grey-light;
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    .upload-icon {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      color:  #BA3A7C;
      font-size: 2em;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto 15px;
      @include mediaM(565px) {
        i {
          font-size: 2rem;
        }
      }
    }
  }
  .preview-space {
    min-height: 290px;
    background-color: $grey-lightest;
    @include mediaM(565px) {
      min-height: 120px;
    }
  }
}


.about{
  .about-content{
    background-image: url(../images/bg-about.jpg);
    background-size: cover;
    background-position: center;
    .about-wrap, .about-wrap2{
      position: relative;
      width: 92%;
      max-width: 1100px;
      margin: 3vh auto;
      .wrap-bg{
          position: absolute;
          background-image: linear-gradient(to right, #E7324E, #BA3A7C);
          width: 96%;
          height: 80%;
          bottom: 10%;
          right: 2%;
          clip-path: polygon(0 0, 95% 0, 100% 15%, 100% 100%, 5% 100%, 0 85%);
          @include mediaM(850px){
              clip-path: polygon(0 0, 90% 0, 100% 10%, 100% 100%, 10% 100%, 0 90%);
              height: 80%;
              width: 90%;
              right: 5%;
              bottom: 2%;
          }
      }
      .wrap-content{
        position: relative;
        display: flex;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: space-between;
        @include mediaM(850px){
            display: block;
        }
        .wrap-text{
          padding: 10px 5%;
          @include mediaM(1050px){
              font-size: 0.8em;
          }
          @include mediaM(850px){
            text-align: center;
            font-size: 0.9em;
            padding: 10px 9% 30px;
          }
        }
      }
    }
    .about-wrap2{
      .wrap-bg{
          position: absolute;
          background-image: linear-gradient(to right, #E7324E, #BA3A7C);
          width: 86%;
          height: 80%;
          bottom: 10%;
          right: 2%;
          clip-path: polygon(0 0, 95% 0, 100% 15%, 100% 100%, 5% 100%, 0 85%);
          @include mediaM(850px){
              clip-path: polygon(0 0, 90% 0, 100% 10%, 100% 100%, 10% 100%, 0 90%);
              height: 80%;
              width: 90%;
              right: 5%;
              bottom: 2%;
          }
      }
      .wrap-content{
        flex-direction: row;
      }
    }
  }
}

.district-info{
  .info{
    background-image: url(../images/bg-hall-of-fame.jpg);
    background-size: cover;
    background-position: center;
    .info-holder{
      border-radius: 10px;
      border: 1px solid #CC2741;
      background-color: rgba(0, 0, 0, 0.4);
      padding: 20px 10px;
      text-align: center;
      p{
        min-height: 199px;
      }
    }
  }
}
.license-info{
  .info{
    background-image: url(../images/bg-game-pad.jpg);
    background-size: cover;
    background-position: center;
    .spread-license, .spread-license2{
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      justify-content: space-between;
      @include mediaM(850px){
          display: block;
      }
      .write-up{
        border-radius: 10px;
        border: 1px solid #CC2741;
        background-color: rgba(0, 0, 0, 0.4);
        padding: 20px 10px;
        p{
          font-size: 0.8em;
          @include mediaM(750px){
            font-size: 0.9em;
          }
        }
      }
    }
    .spread-license{
      flex-direction: row;
    }
  }
}

.connect{
  .info{
    background-image: url(../images/bg-game-pad.jpg);
    background-size: cover;
    background-position: center;
    padding-bottom: 10vh;
    .connect-box{
      padding: 20px 12px;
      background-color: rgba(0, 0, 0, 0.4);
      border-radius: 10px;
      border: 1px solid #CC2741;
      .connection-capsule {
        border: 1px solid $grey-light;
        border-radius: 6px;
        cursor: pointer;
        transition: 0.3s ease;
        padding: 6px 9px;
        &:hover {
          box-shadow: 0 1px 8px 0 $black-faint4;
          background-color: rgba(109, 18, 65, 0.6);
        }
        &:active {
          transform: scale(1.04);
        }
      }
    }
  }
}

.purchase-item{
  .banner{
    background-image: url(../images/bg-hero.jpg);
    background-size: cover;
    background-position: center;
  }
  .info{
    background-image: url(../images/bg-hall-of-fame.jpg);
    background-size: cover;
    background-position: center;
    padding: 8vh 3% 12vh;
    .item-picture{
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.4);
      border: 1px solid rgba(255, 255, 255, 0.2);
      padding: 10px;
      @include mediaW(768px){
        min-height: 100%;
        img{
          width: 100%;
          border-radius: 6px;
        }
      }
    }
    .item-detail{
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.4);
      border: 1px solid #CC2741;
      padding: 10px;
      @include mediaW(768px){
        min-height: 100%;
      }
    }
  }
}

.profile{
  .info{
    background-image: url(../images/bg-game-pad.jpg);
    background-size: cover;
    background-position: center;
    padding-bottom: 10vh;
    .personal-info{
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.4);
      border: 1px solid #CC2741;
      padding: 10px;
    }
    .selecting-sect{
      height: calc(100% - 30px);
      max-height: 90vh;
      padding: 10px;
      .selecting-space{
        overflow: auto;
        height: 100%;
        .item{
          border-radius: 6px;
          background-color: rgba(0, 0, 0, 0.4);
          border: 1px solid #CC2741;
          padding: 7px;
          .image-holder{
            width: 100%;
            height: 0;
            padding-bottom: 60%;
            background-position: center;
            background-size: cover;
          }
          img{
            width: 100%;
            border-radius: 4px;
          }
        }
      }
    }
    .item-picture{
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.4);
      border: 1px solid rgba(255, 255, 255, 0.2);
      padding: 10px;
      @include mediaW(768px){
        min-height: 100%;
        img{
          width: 100%;
          border-radius: 6px;
        }
      }
    }
    .item-detail{
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.4);
      border: 1px solid #CC2741;
      padding: 10px;
      @include mediaW(768px){
        min-height: 100%;
      }
    }
  }
}









.hover-zoom{
  transition: 0.3s ease;
  &:hover{
    transform: scale(1.07);
  }
}

.news{
  .hero{
    position: relative;
    overflow: hidden;
    .background{
      position: absolute;
      left: 10%;
      top: 5vh;
      width: 80%;
    }
  }
}


.vid-modal{
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.4s ease;
  opacity: 0;
  .modal-bg{
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: $black-faint2;       
  }
  .modal-container{
      width: 90%;
      max-width: 700px;
      position: relative;
      .modal-content{
          position: relative;
          width: 100%;
          border-radius: 15px;
          height: 0;
          padding-bottom: 56.25%;
          background-color: $white;
          transition: 0.4s ease;
          transform: scale(0.001);
          .stream-vid{
              position: absolute;
              width: 100%;
              height: 100%;
              top: 0;
              left: 0;
              border-radius: 15px;
              overflow: hidden;
          }
          .modal-closer{
              position: absolute;
              top: - 40px;
              right: -10px;
              border-radius: 25%;
              opacity: 0.5;
              background-color: $red;
              color: $white;
              padding: 3px 10px;
              transition: 0.2s ease;
              @include mediaM(500px) {
                  padding: 4px 8px 3px;
              }
              &:hover{
                  background-color: $red-hover;
                  opacity: 1;
              }
              &:active{
                  transform: scale(1.1);
                  background-color: $red-active;
              }
          }
      }
  }
}


@keyframes zoom-bg {
  0% { background-size: 100%; }
  30% { background-size: 100%; }
  50% { background-size: 120%; }
  80% { background-size: 120%; }
  100% { background-size: 100%; }
}
@keyframes zoom-bg-mobile {
  0% { background-size: 380%; }
  30% { background-size: 380%; }
  50% { background-size: 450%; }
  80% { background-size: 450%; }
  100% { background-size: 380%; }
}
 @keyframes slide-gradient {
  0% {background-color: rgba(0, 0, 0, 0.7)}
  16% {background-color: rgba(0, 0, 0, 0.7)}
  34% {background-color: rgba(59, 9, 0, 0.7)}
  50% {background-color: rgba(59, 9, 0, 0.7)}
  66% {background-color: rgba(0, 9, 51, 0.7)}
  84% {background-color: rgba(0, 9, 51, 0.7)}
  100% {background-color: rgba(0, 0, 0, 0.7)}
 }
 @keyframes fade-background {
  0% { opacity: 1; }
  10% { opacity: 0; }
  50% { opacity: 0; }
  60% { opacity: 1; }
  100% { opacity: 1; }
 }