// Color variables

$primary-green: #61c38e;
$primary-green-hover: #34895b;
$primary-green-active: #34895b;

$primary-green-hover-faint1: rgba(52, 117, 58, 0.7);
$primary-green-hover-faint2: rgba(52, 117, 58, 0.45);
$primary-green-hover-faint3: rgba(52, 117, 58, 0.2);
$primary-green-hover-faint4: rgba(52, 117, 58, 0.1);
$primary-green-hover-faint5: rgba(52, 117, 58, 0.06);

$primary-shade: #041926;
$primary-shade-light: #07263a;

$primary-bg: #F6F9FF;

$primary-pale: #ba3a7c;
$primary-pale-hover: #9b2b65;
$primary-pale-active: #6e1744;

$secondary-pale: #A771EC;
$secondary-pale-hover: #713ab8;

$primary-pale-faint1: rgba(186, 58, 124, 0.7);
$primary-pale-faint2: rgba(186, 58, 124, 0.451);
$primary-pale-faint3: rgba(186, 58, 124, 0.2);
$primary-pale-faint4: rgba(186, 58, 124, 0.1);
$primary-pale-faint5: rgba(186, 58, 124, 0.06);

$primary-green-faint1: rgba(97, 195, 142, 0.7);
$primary-green-faint2: rgba(97, 195, 142, 0.45);
$primary-green-faint3: rgba(97, 195, 142, 0.2);
$primary-green-faint4: rgba(97, 195, 142, 0.1);
$primary-green-faint5: rgba(97, 195, 142, 0.06);

$secondary-blue: #567df4;
$secondary-blue-hover: #3d5bb4;
$secondary-blue-active: #1e3270;

$base-color: #f6fbfe;
$base-color2: rgba(207, 237, 221, 0.678);
$base-highlight: #f6f9fe;

$light-blue: #eef7fe;
$light-orange: #ffeed5;
$orange: #f7a02c;
$orange-mid-dark: #df7e00;
$orange-dark: #ca7300;
$light-cyan: #b2f0fb;

$white: #ffffff;
$white-faint1: rgba(255, 255, 255, 0.7);
$white-faint2: rgba(255, 255, 255, 0.45);
$white-faint3: rgba(255, 255, 255, 0.2);

$off-white: #f1f3f6;
$off-black: #041926;

$black: #000000;
$black-faint1: rgba(0, 0, 0, 0.7);
$black-faint2: rgba(0, 0, 0, 0.45);
$black-faint3: rgba(0, 0, 0, 0.2);
$black-faint4: rgba(0, 0, 0, 0.1);

$grey-lightest: #eff0f2;
$grey-light: #cccccc;
$grey-mid: #818181;
$grey-dark: #4f5873;

$faint-font: rgba(34, 33, 91, 0.6);

$brown: #4d2e44;
$red: #ff0101;
$red-hover: #a10000;
$red-active: #530000;
$red-faded: #eb5757;
$pink: #ff00b5;
// $yellow: #F7A02C;
$dirty-green: #587d71;

$transparent: rgba(0, 0, 0, 0);

// Mixins

@mixin mediaM($value) {
  @media all and (max-width: $value) {
    @content;
  }
}
@mixin mediaW($value) {
  @media all and (min-width: $value) {
    @content;
  }
} ;
