@import "./variables.scss";

.header {
	transition: 0.3s ease;
	padding: 15px 0;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 100;
	color: $white;
	box-shadow: 0 4px 4px 0 rgba(255, 255, 255, 0.15);
	.sub-layer {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: $black;
	}
	.spread-nav-web {
		display: flex;
		align-items: center;
		justify-content: space-between;
		@include mediaM(850px) {
			display: block;
		}
	}
	.logo {
		width: 150px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 12px;
		// box-shadow: 0 0 10px 1px $white-faint1;
		// background-color: $black-faint1;
		img {
			width: 70px;
		}
	}
	.logo-mini {
		width: 110px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-top: 10px;
		padding: 9px;
		// box-shadow: 0 0 10px 1px $white-faint1;
		// background-color: $black-faint1;
		img {
			width: 50px;
		}
	}
	.mobile-menu {
		width: 100px;
		text-align: right;
		i {
			font-size: 2em;
		}
	}
	.mobile-menu2 {
		i {
			font-size: 2em;
		}
	}

	.subscribe-grid {
		width: 400px;
	}
	.nav {
		display: block;
		transition: 0.3s ease;
		ul {
			margin: 0;
			padding: 0;
			li {
				display: inline-block;
				padding: 5px 16px;
				font-size: 0.9em;
				transition: 0.2s;
				@include mediaM(750px) {
					padding-top: 20px;
				}
				&:hover {
					transform: scale(1.1);
					background-color: $black;
				}
				button {
					padding: 10px 20px;
					border-radius: 30px;
					background-image: linear-gradient(to right, #e7324e, #ba3a7c);
					border: none;
					&:focus {
						outline: none;
					}
				}
				span {
					padding: 10px 5px;
					display: block;
					// box-shadow: 0 0 10px 1px $white-faint1;
					background-color: $black-faint1;
					font-size: 1.3em;
					font-weight: 500;
					transition: 0.3s ease;
					i {
						transform: translateY(2px);
					}
				}
			}
		}
		.full-half {
			color: $primary-pale;
			background-color: $white;
			border-radius: 20px;
			padding: 5px 20px;
			border: 0.5px solid $black-faint4;
		}
	}
	@include mediaM(850px) {
		padding: 0;
		.nav {
			ul {
				li {
					display: block;
				}
				padding-bottom: 15px;
			}
		}
	}
}
.select-switch {
	width: 70px;
	background-color: #ffffff;
	height: 40px;
	padding: 5px;
	border-radius: 20px;
	div {
		width: 30px;
		height: 30px;
		border: "10px solid white";
		border-radius: 50%;
		background-color: #e7324e;
	}
	.item-side {
		margin-left: 30px;
	}
}

.header-fill {
	width: 100%;
	height: 100px;
	@include mediaM(850px) {
		height: 78px;
	}
}

.mobile-notification {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 33px;
	height: 33px;
	color: $black-faint1;
	background-color: $white;
	border-radius: 20px;
	border: 0.5px solid $black-faint4;
}
.mobile-user-menu {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 33px;
	height: 33px;
	background-color: $primary-pale;
	color: $white;
	border-radius: 20px;
	.draw-holder,
	.draw-holder-mobile-user {
		color: $black;
	}
}

.search-hover-button {
	position: relative;
	background-color: $primary-pale;
	color: $white;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	width: 40px;
	height: 40px;
	cursor: pointer;
	z-index: 9;
	.holder {
		position: absolute;
		top: 130%;
		left: 50%;
		transform: translate(-50%, -50%);
		pointer-events: none;
		opacity: 0;
		transition: 0.3s ease;
	}
	&:hover {
		.holder {
			opacity: 1;
			pointer-events: all;
			transform: translate(-50%, 0);
		}
	}
}
.search-holder {
	position: relative;
	z-index: 9;
	.search-button {
		background-color: $primary-pale;
		color: $white;
		border-radius: 50%;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		width: 40px;
		height: 40px;
		cursor: pointer;
	}
	.holder {
		position: absolute;
		top: 130%;
		left: 50%;
		transform: translate(-50%, -50%);
		pointer-events: none;
		opacity: 0;
		transition: 0.3s ease;
	}
	// &:hover{
	//     .holder{
	//         opacity: 1;
	//         pointer-events: all;
	//         transform: translate(-50%, 0);
	//     }
	// }
	.active-holder {
		opacity: 1;
		pointer-events: all;
		transform: translate(-50%, 0);
	}
}

.subscribe-grid {
	display: grid;
	grid-template-columns: auto 70px;
	border-radius: 10px;
	box-shadow: 0 0 8px 1px $black-faint4;
	border: 0.5px solid $black-faint4;
	max-width: 80vw;
	overflow: hidden;
	input,
	button {
		height: 100%;
		border: none;
	}
	input {
		padding: 12px 15px;
		background-color: $white-faint3;
		border-radius: 10px 0 0 10px;
		border: 1px solid $white;
		color: $white;
		&:focus {
			outline: none;
		}
	}
	button {
		background-color: $primary-pale;
		color: $white;
		transition: 0.3s ease;
		cursor: pointer;
		&:hover {
			background-color: $primary-pale-hover;
		}
		&:active {
			background-color: $primary-pale-active;
		}
		&:focus {
			outline: none;
		}
	}
}
.inc-grid {
	grid-template-columns: auto 15px 140px;
	@include mediaM(750px) {
		grid-template-columns: auto 12px 100px;
	}
	@include mediaM(500px) {
		grid-template-columns: auto 10px 86px;
	}
}
.click-catcher-obj {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
}

.draw-holder,
.draw-holder-web {
	position: absolute;
	top: calc(100% + 20px);
	left: 50%;
	transform: translate(-50%, -50%);
	pointer-events: none;
	opacity: 0;
	transition: 0.3s ease;
}
.draw-holder-web {
	@include mediaM(850px) {
		margin-top: 10px;
		position: relative;
	}
}
.draw-holder-mobile-notification {
	position: absolute;
	top: calc(100% + 20px);
	right: -60px;
	transform: translateY(-50%);
	pointer-events: none;
	opacity: 0;
	transition: 0.3s ease;
	z-index: 9;
}
.draw-holder-mobile-user {
	position: absolute;
	top: calc(100% + 20px);
	right: -10px;
	transform: translateY(-50%);
	pointer-events: none;
	opacity: 0;
	transition: 0.3s ease;
	z-index: 9;
}
.notification-draw {
	width: 300px;
	max-width: 80vw;
}

.activator {
	opacity: 1;
	pointer-events: all;
	transform: translate(-50%, 0);
}
.activator-mobile {
	opacity: 1;
	pointer-events: all;
	transform: translateY(0);
}
.copy-card {
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: $primary-pale-faint5;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $primary-pale;
}

.open-mobile-menu {
	@include mediaM(850px) {
		background-color: $black;
		.nav {
			max-height: 200vh;
		}
	}
}

.close-mobile-menu {
	.nav {
		@include mediaM(850px) {
			max-height: 0;
			overflow: hidden;
		}
	}
}

.footer {
	// border-top: 0.2px solid $white;
	background-image: url(./../images/footer_bg.jpg);
	color: $white;
	h6 {
		@include mediaW(768px) {
			font-size: 1.3em;
		}
	}
	.dynamic-topic {
		span {
			@include mediaW(768px) {
				font-size: 0.85em;
			}
		}
	}
	.dynamic-info {
		span {
			@include mediaW(768px) {
				font-size: 0.8rem;
			}
		}
	}
	.footer-logo {
		width: 110px;
	}
	p {
		color: $white;
		a {
			color: inherit;
			opacity: 0.9;
			&:hover {
				opacity: 0.6;
				text-decoration: none;
			}
		}
	}
	p,
	a,
	input,
	button {
		@include mediaW(768px) {
			font-size: 0.9rem;
		}
	}
	p {
		margin-bottom: 10px !important;
	}
	.footer-base {
		background-color: $black;
	}
}
.input-sect {
	label {
		font-size: 13px;
		font-weight: 500;
	}
	input,
	select,
	textarea {
		width: 100%;
		border-radius: 6px;
		padding: 10px 13px;
		border: 1px solid gray;
		transition: 0.3s ease;
		&:hover {
			box-shadow: 0 0 8px 1px black;
		}
		&:focus {
			outline: none;
			border-color: #ba3a7c;
		}
	}
}
.pagination {
	color: black;
	float: left;
	padding: 8px 16px;
	text-decoration: none;
}

.active {
	color: white;
	border-radius: 5px;
}

.pagination a:hover:not(.active) {
	background-color: #ddd;
	border-radius: 5px;
}
.responsive-table li {
	padding: 5px 30px;
	display: flex;
	justify-content: space-between;
}
.responsive-table .table-header {
	background-color: #95a5a6;
	font-size: 14px;
	text-transform: uppercase;
	letter-spacing: 0.03em;
}
.responsive-table .table-row {
	background-color: #ffffff;
	box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
}
.responsive-table .col-1 {
	flex-basis: 10%;
}
.responsive-table .col-2 {
	flex-basis: 40%;
}
.responsive-table .col-3 {
	flex-basis: 25%;
}
.responsive-table .col-4 {
	flex-basis: 25%;
}
@media all and (max-width: 767px) {
	.responsive-table .table-header {
		display: none;
	}
	.responsive-table li {
		display: block;
	}
	.responsive-table .col {
		flex-basis: 100%;
	}
	.responsive-table .col {
		display: flex;
		padding: 10px 0;
	}
	.responsive-table .col:before {
		color: #6c7a89;
		padding-right: 10px;
		content: attr(data-label);
		flex-basis: 50%;
		text-align: right;
	}
}
